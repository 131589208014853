import request from '@/utils/request'

// 查询门票信息列表
export function listTicket(query) {
  return request({
    url: '/ticket/ticket/list',
    method: 'get',
    params: query
  })
}

// 查询门票信息详细
export function getTicket(id) {
  return request({
    url: '/ticket/ticket/' + id,
    method: 'get'
  })
}

// 新增门票信息
export function addTicket(data) {
  return request({
    url: '/ticket/ticket',
    method: 'post',
    data: data
  })
}

// 修改门票信息
export function updateTicket(data) {
  return request({
    url: '/ticket/ticket',
    method: 'put',
    data: data
  })
}

// 删除门票信息
export function delTicket(id) {
  return request({
    url: '/ticket/ticket/' + id,
    method: 'delete'
  })
}
